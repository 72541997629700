import sourceData from '@/data/home_preview_projects.json'

/*
    Pour effectuer la vérification de l'existence de la route dans notre router
    il nous faut récupérer les données du fichier .json
*/

const FINAL_PAGE_ROUTES = [

    // {
    //     name: 'Maintenance',
    //     path: '/',
    //     component: () => import(/*webpackChunkName:"Home"*/"@/views/ComingSoonOrMaintenance.vue"),

    //     meta:{
    //          // title:'Home — lnacarla'
    //          title:'LNACARLA - MAINTENANCE'
    //     },
    // },
    {
        name: 'Home',
        path: '/',
        component: () => import(/*webpackChunkName:"Home"*/"@/views/Home.vue"),
        meta:{
             // title:'Home — lnacarla'
             title:'LNACARLA',
             transition: 'shutters'
        },
        beforeEnter(){
            document.body.style.overflowY = "visible"
        }
    },
    {
        name: 'About',
        path: '/about',
        component: () => import(/*webpackChunkName:"About"*/"@/views/About.vue"),
        meta:{
            title:'About — lnacarla',
            transition: 'shutters'
        },
        beforeEnter(to, from){
            const toDepth   = to.path.split('/').length
            // console.log("to      =", to)
            // console.log("from    =", from)
            // console.log("to.path =", to.path)

            const fromDepth = from.path.split('/').length
            to.meta.transitionName = toDepth < fromDepth ? 'shutters' : 'shutters'
        }
    },
    {
        name: 'Projects',
        path: '/projects',
        component: () => import(/*webpackChunkName:"Projects"*/"@/views/Projects.vue"),
        meta:{
            title:'Projects — lnacarla',
            transition: 'right'
        },
    },
    {
        name: 'project.show',
        path:'/portfolio/:slug',
        component: () => import(/*webpackChunkName:"ProjectShow"*/"@/views/ProjectShow.vue"),
        props: route => ({...route.params, slug: route.params.slug}),
        meta:(route) => ({ title: route.params.slug, transition: 'right' }),

        beforeEnter(to){
            // document.body.scrollTop = 0;

            /*
                On renvoie la valeur de project si le slug de la route du projet
                est strictement égale à celui du projet récupérée depuis le fichier.json
            */

            const exists = sourceData.projects.find(
                // L'id doit être une nombre
                // project => project.id === parseInt(to.params.id)
                project => project.slug === to.params.slug
            )

            // console.log("to.params           =", to.params)
            // console.log("sourceData.projects =", sourceData.projects)
            // console.log("to.params.slug      =", to.params.slug)

            // Si l’id n’existe pas ou undefined : on retourne le composant NotFound.vue
            if(!exists){
                // console.log("path       =", to.path)
                // console.log("pathMatch =", {pathMatch: to.path.split('/').slice(1)})

                return{
                    name:'NotFound',
                    // Allows keeping the url while rendering  different page
                    params: {pathMatch: to.path.split('/').slice(1)},
                    path: to.params.slug,
                    query: to.query,
                    hash: to.hash
                }
            }
        },
        // https://router.vuejs.org/guide/advanced/navigation-guards.html#global-after-hooks
        afterEach() {
            // document.querySelector('#projectShow_nav--firstPartName').style.opacity = 0;
            // document.querySelector('#projectShow_nav--secondPartName').style.opacity = 0;
            // document.querySelector('#projectShow_nav--thirdPartName').style.opacity = 0;
        }
    },
    {
        name: 'NotFound',
        path: '/:pathMatch(.*)*',
        component: () => import(/*webpackChunkName:"NotFound"*/'@/views/NotFound.vue'),
        meta:{
            title:'NotFound — lnacarla'
        },
    },
]


export  {
    // ------------------ FINAL PAGE COMPONENTS -----------------
    FINAL_PAGE_ROUTES
};