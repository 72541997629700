import TestGSAP from "../components/playground_components/TestGSAP.vue";

const PLAYGROUND_ROUTES = [
      {
        path: "/test-gsap-animation",
        component: TestGSAP,
        meta:{
          title:'Test with GSAP',
        },
      }
]

export {
    // ---------------- EDIT MODE PAGE ROUTES --------------
    PLAYGROUND_ROUTES
};