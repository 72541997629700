<style scoped>
    @import '../assets/css/theNavigation.scss';
</style>
<template>
  <!-- OVERLAY PAGE TRANSITION -->
  <!-- <div class="theNavigation__left-layer"></div> -->

  <!-- TheNavigation;vue is the children of the components App.vue -->
  <nav id="test" class="theNavigation__navbar">
      <!-- <div class="theNavigation__home__link theNavigation__topleft" v-show="$route.name !== 'About'
                                                                    && $route.name !== 'Maintenance'
                                                                    && $route.name !== 'Projects'
                                                                    && $route.name !== 'Home'">
           <router-link class="theNavigation__effect-shine" to='/'>OLY</router-link>
      </div> -->
      <div class="theNavigation__backHome__link theNavigation__bottomleft"
            v-show="$route.name !== 'Maintenance' && $route.name !== 'Home'">
            <Transition name="theNavigation__fade">
               <router-link class="theNavigation__effect-shine" to='/'>Home</router-link>
            </Transition>
      </div>
      <!-- <div class="theNavigation__projects__link theNavigation__topright" v-show="$route.name !== 'About'
                                                                         && $route.name !== 'Maintenance'
                                                                         && $route.name !== 'Projects'">
         <router-link class="theNavigation__effect-shine" to='/projects'>Projects</router-link>
      </div> -->
      <!-- <div v-show="$route.name === 'About' && $route.name !== 'Maintenance'" class="theNavigation__about__navigation">
        <button id="about__copied__button"
                class="theNavigation__btn"
                v-on:click="copyEmail('lnacarla.photography@gmail.com')"></button>
      </div> -->
  </nav>
  <!-- <nav class="theNavigation__navbarAbout theNavigation__topright" v-show="$route.name === 'About'">

  </nav> -->
  <footer class="theNavigation__bottomBar">
      <div class="theNavigation__about__link theNavigation__bottomleft">
          <!-- <Transition name="theNavigation__fade"> -->
            <router-link @click="show = !show" class="theNavigation__effect-shine" to='/about'
                v-show="$route.name === 'Home'">
                About
            </router-link>
          <!-- </Transition> -->
      </div>
      <div class="theNavigation__social__links theNavigation__bottomright"
           v-show="$route.name !== 'Maintenance' && $route.name !== 'Projects'
                                                 && $route.name === 'About'
                                                 && $route.name !== 'Home'
                                                 || $route.name === 'project.show'">
        <a href="https://www.facebook.com/lnacarlaphotography/"
           class="theNavigation__social__link--item
                  theNavigation__social__links__facebook
                  theNavigation__effect-shine">
            <SimpleIconsFacebook/>
        </a>
        <a href="https://www.instagram.com/lnacarla/"
           class="theNavigation__social__link--item
                  theNavigation__social__links__instagram
                  theNavigation__effect-shine">
            <LineMdInstagramAnimated/>
        </a>
      </div>
      <div class="theNavigation__projects_link theNavigation__bottomright"
           v-show="$route.name !== 'Maintenance' && $route.name == 'Home'">
            <Transition name="theNavigation__fade">
                <router-link class="theNavigation__effect-shine" to='/projects'>Projects</router-link>
            </Transition>
      </div>
  </footer>
</template>


<script>
// import $ from 'jquery'
// import SkillIconsInstagram from '../components/icons/social-network/SkillIconsInstagram.vue'
// import LogosInstagram from '../components/icons/social-network/LogosInstagram.vue'
import SimpleIconsFacebook from '../components/icons/social-network/SimpleIconsFacebook'
import LineMdInstagramAnimated from '../components/icons/social-network/LineMdInstagramAnimated.vue'

export default {
  name: 'TheNavigation',
  components: {
    SimpleIconsFacebook,
    LineMdInstagramAnimated,
    // SkillIconsInstagram,
    // LogosInstagram

  },
  data() {
    return {
      show: true
     }
  },
  props:{
      routeName:{
          type: String, required: false
      }
  },
  mounted: function(){
        // content: attr(data-value);
        // On assigne une valeur par défaut dans le attr du css btn:after
        // var btn = document.getElementById('about__copied__button');
        // btn.setAttribute('data-value', 'Copy Email');

        // Overlay Animation Page transition
        // https://codepen.io/mburakerman/pen/roJmaZ

        // const buttons = document.getElementsByTagName("button");
        // const buttons = document.querySelectorAll(".theNavigation__effect-shine");

        // for (const button of buttons) {
        //   console.log("button =", button);

        //   button.addEventListener('click', () => {
        //     console.log("click event")
        //     var id = button.getAttribute("id");
        //     var layerClass = ".theNavigation__" + id+ "-layer";
        //     var layers = document.querySelectorAll(layerClass);
        //     for (const layer of layers) {
        //       layer.classList.toggle("active");
        //     }
        //   });
        // }
  },
  updated: function(){
      // --- How to get the name of the current route
      // console.log("currentRoute name =", this.$router.currentRoute._value.name);
  },
  methods: {
      // copyEmail: function (TextToCopy) {

      //   var btn = document.getElementById('about__copied__button');
      //   // content: attr(data-value);
      //   btn.setAttribute('data-value', 'EMAIL COPIED');

      //   // On désactive le boutton
      //   $("#about__copied__button").prop('disabled', true);

      //   setTimeout(function(){
      //         btn.setAttribute('data-value', 'COPY EMAIL');
      //         // On réactive le boutton
      //         $("#about__copied__button").prop('disabled', false);

      //    }, 2500);

      //   var TempText   = document.createElement("input");
      //   TempText.value = TextToCopy;
      //   document.body.appendChild(TempText);
      //   TempText.select();
      //   TempText.setSelectionRange(0, 99999); /* For mobile devices */

      //   document.execCommand("copy");
      //   document.body.removeChild(TempText);

      // }
    },
  // computed: {
  //   instagramIcon () {
  //     return faInstagram
  //   },
  //   facebookIcon () {
  //     return faFacebook
  //   }
  // }
}
</script>