import HomeEditModeAuth from "../components/authentication/HomeEditMode.vue";
import Login from "../components/authentication/Login.vue";
import Register from "../components/authentication/Register.vue";
// lazy-loaded
const Profile        = () => import("../components/authentication/Profile.vue")
const BoardAdmin     = () => import("../components/authentication/BoardAdmin.vue")
const BoardModerator = () => import("../components/authentication/BoardModerator.vue")
const BoardUser      = () => import("../components/authentication/BoardUser.vue")

const AUTHENTICATION_ROUTES = [
      {
        path: "/home-edit-mode-auth",
        component: HomeEditModeAuth,
        meta:{
          title:'HOME EDIT MODE AUTH',
          mode:'EDITMODE',
        },
      },
      {
        path: "/login",
        component: Login,
        meta:{
          title:'LOGIN EDIT MODE',
          mode:'EDITMODE',
        },
      },
      {
        path: "/register",
        component: Register,
        meta:{
          title:'REGISTER EDIT MODE',
          mode:'EDITMODE',
        },
      },
      {
        path: "/profile",
        name: "profile",
        // lazy-loaded
        component: Profile,
        meta:{
          title:'PROFILE EDIT MODE',
          mode:'EDITMODE',
        },
      },
      {
        path: "/admin",
        name: "admin",
        // lazy-loaded
        component: BoardAdmin,
        meta:{
          title:'ADMIN EDIT MODE',
          mode:'EDITMODE',
        },
      },
      {
        path: "/mod",
        name: "moderator",
        // lazy-loaded
        component: BoardModerator,
        meta:{
          title:'MODERATOR EDIT MODE',
          mode:'EDITMODE',
        },
      },
      {
        path: "/user",
        name: "user",
        // lazy-loaded
        component: BoardUser,
        meta:{
          title:'USER EDIT MODE',
          mode:'EDITMODE',
        },
      },
]


export {
    // ---------------- EDIT MODE PAGE ROUTES --------------
    AUTHENTICATION_ROUTES
  };