import { createRouter, createWebHistory } from "vue-router";
import { EDIT_MODE_ROUTES } from "./edit-mode-pages-routes.js"
import { FINAL_PAGE_ROUTES } from "./final-page-routes.js"
import { AUTHENTICATION_ROUTES } from "./authentication-routes.js"
import { EDIT_MODE_PROJECTS_ROUTES } from "./edit-mode-projects-routes.js"
import { PLAYGROUND_ROUTES } from "./playground-routes.js"

// Merge two or more arrays
const routes = EDIT_MODE_ROUTES.concat( FINAL_PAGE_ROUTES,
                                        AUTHENTICATION_ROUTES,
                                        EDIT_MODE_PROJECTS_ROUTES,
                                        PLAYGROUND_ROUTES  )


const router = createRouter({
    history: createWebHistory(),
    routes,

    // Nous voulons retourner la position sauvegardée du scroll
    // Et fixer la valeur top du scroll à zéro
    // scrollBehavior(to, from, savedPosition){
    //     console.log("to.params.slug =", to.params.slug)
    //     return savedPosition || new Promise((resolve) =>{
    //         // Nous pouvons rendre le comportement scroll plus fluide  grâce à la propriété behavior => smooth
    //         // setTimeout(() => resolve({top:0, behavior:'smooth'}), 300)
    //         setTimeout(() => resolve({top:0}), 0)
    //     })

    // Nous faisons un scrollTop à chaque routing ou refreshing
    // https://router.vuejs.org/guide/advanced/scroll-behavior.html#delaying-the-scroll
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
    },
    /*
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition
        } else {
          return { top: 0 }
        }
      },
    */
})

// Dynamic Page Titles With The Vue 3 Router
router.beforeEach((to, from, next) => {

    // document.body.style.overflowX = "hidden"
    // document.body.style.overflowY = "visible"
    document.body.classList.remove(["oh","view-content"])

    // Hidden all the page content during the transition
    // document.body.style.opacity = 0
    // document.documentElement.style.opacity = 0


    // function capitalizeFirstLetter(string) {
    //     return string.charAt(0).toUpperCase() + string.slice(1);
    // }

    function capitalizeFull(string) {
        /*  replaceAll() - DOCUMENTATION:
                https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Global_Objects/String/replaceAll
            ...
            La méthode replaceAll() retourne une nouvelle chaîne de caractères
            dans laquelle toutes les occurrences d'un motif donné ont été remplacées
            par une chaîne de remplacement.
            ...
        */
        return string.toUpperCase().replaceAll("-"," ");
    }
    // On check si la route contient des params spécifiques au projet
    // Si les params sont indéfinies cela signifie que la route n'est pas une route projet
    document.title = `${to.meta.title}`;

    // On check si le paramètre slug de la route projet est bien présent
    if(typeof(to.params.slug) !== 'undefined'){
        document.title =  capitalizeFull(`${to.params.slug} — LNACARLA`);
    }
    // console.log("to.params           =", to.params)
    // console.log("sourceData.projects =", sourceData.projects)
    // console.log("to.params.slug      =", to.params.slug)

    next();
})


// DOCU - https://router.vuejs.org/guide/advanced/navigation-guards.html#global-resolve-guards
router.beforeResolve(() => {

    setTimeout(() =>  {

        // We're enabling the scroll property
        document.body.style.overflowY = 'visible';
        document.documentElement.style.overflowY = 'visible', 3000
    });
})

// DOCU - https://router.vuejs.org/guide/advanced/transitions.html#route-based-dynamic-transition
router.afterEach((to, from) => {

    // Show all the page content during the transition
    document.body.style.opacity = 1
    document.documentElement.style.opacity = 1


    // Dynamically disable all clicks on page
    // let freezeClic = true; // just modify that variable to disable all clics events

    // document.addEventListener("click", e => {
    //     if (freezeClic) {
    //         e.stopPropagation();
    //         e.preventDefault();
    //     }
    // }, true);



    const toDepth   = to.path.split('/').length
    // console.log("to      =", to)
    // console.log("from    =", from)
    // console.log("to.path   =", to.path)
    // console.log("from.path =", from.path)

    const fromDepth = from.path.split('/').length

    // -------- REMOVE TRANSITION FOR EDIT MODE PAGES -------
    if(to.meta.mode !== 'EDITMODE' || from.meta.mode !== 'EDITMODE' ){
        to.meta.transitionName = toDepth < fromDepth ? 'shutters' : 'shutters'
    }

    /*
        Changing the background color after each route changing
        if it's not the same route
    */
    document.body.style.overflowX = "hidden"
    document.body.style.overflowY = "visible"

    // We disable the scroll property
    // document.body.style.overflowY = 'hidden'; // Warning bugs
    // document.documentElement.style.overflowY = 'hidden'; // Warning bugs

    document.body.classList.remove(["oh","view-content"])

    if(to.path !== from.path){
       // document.body.style.backgroundColor = "black"
       // document.body.style.opacity = 0

    }
})

export default router;