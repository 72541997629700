<style scoped>
    @import '../../../assets/css/edit-mode/theNavigationEditMode.scss';
</style>
<template>
  <div class="theNavigationEditMode--navbar--container">
    <nav class="theNavigationEditMode--navbar">
      <div class="theNavigationEditMode--navbar-brand">
          <a href="/">LNACARLA</a>
      </div>
      <!-- If user is connected or registered -->
      <div v-if="currentUser" class="theNavigationEditMode--profile">
          <router-link to="/profile" class="theNavigationEditMode--nav-link">
            <Tooltip :tooltipText="'Profile'"
                  :flow="'right'">
                <MaterialSymbolsAccountCircle/> {{ currentUser.username.toUpperCase() }}
            </Tooltip>
          </router-link>
      </div>
      <!-- Dropup menu -->
      <div class="theNavigationEditMode--menu-wrap">
        <div class="theNavigationEditMode--menu">
            <div class="theNavigationEditMode--menu-item">
                <a href="#">Menu</a>
                <!-- MENU CONTENT DROPDOWN MENU -->
                <div class="theNavigationEditMode--drop-menu">
                  <div class="theNavigationEditMode--navbar-nav">
                      <!-- Home Edit Mode -->
                      <div v-if="currentUser" class="theNavigationEditMode--nav-item">
                        <router-link to="/edit-mode-projects" class="theNavigationEditMode--nav-link">
                          <Tooltip :tooltipText="'Home'"
                                :flow="'right'">
                            <IcSharpHome/>
                          </Tooltip>
                        </router-link>
                      </div>

                      <!-- Admin / Moderator / User -->
                      <div v-if="showAdminBoard" class="theNavigationEditMode--nav-item">
                        <router-link to="/admin" class="theNavigationEditMode--nav-link">
                          <Tooltip :tooltipText="'Admin Board'"
                                :flow="'right'">
                              <ClarityAdministratorSolid/>
                          </Tooltip>
                        </router-link>
                      </div>

                      <!-- Moderator Board -->
                      <div v-if="showModeratorBoard" class="theNavigationEditMode--nav-item">
                        <router-link to="/mod" class="theNavigationEditMode--nav-link">
                          <Tooltip :tooltipText="'Moderator Board'"
                                :flow="'right'">
                              <MaterialSymbolsVerifiedUserOutline/>
                          </Tooltip>
                        </router-link>
                      </div>

                      <!-- User Board -->
                      <div class="theNavigationEditMode--nav-item">
                        <router-link v-if="currentUser" to="/user" class="theNavigationEditMode--nav-link">
                          <Tooltip :tooltipText="'User Board'"
                                :flow="'right'">
                              <MaterialSymbolsPerson/>
                          </Tooltip>
                        </router-link>
                      </div>
                  </div>

                  <!-- current user infos and connection state -->
                  <div class="theNavigationEditMode--nav-item">
                    <!-- If user not connected or registered -->
                    <div v-if="!currentUser" class="">
                      <router-link to="/register" class="theNavigationEditMode--nav-link">
                        <IcSharpPersonAddAlt/> Sign Up
                      </router-link>
                    </div>
                    <!--  -->
                    <div v-if="!currentUser" class="">
                      <router-link to="/login" class="theNavigationEditMode--nav-link">
                        <RiLoginBoxLine/> Login
                      </router-link>
                    </div>
                    <!--  -->
                    <div v-if="currentUser" class="theNavigationEditMode--nav-item">
                          <a class="theNavigationEditMode--nav-link" @click.prevent="logOut">
                            <Tooltip :tooltipText="'Logout'"
                              :flow="'right'">
                                <RiLogoutBoxLine/>
                            </Tooltip>
                          </a>
                    </div>
                  </div>
                </div>
            </div>
          </div>
      </div>

    </nav>
  </div>
</template>

<script scoped>
import IcSharpHome from '../../../components/icons/authentication/home/IcSharpHome.vue'
import RiLoginBoxLine from '../../../components/icons/authentication/login/RiLoginBoxLine.vue'
import RiLogoutBoxLine from '../../../components/icons/authentication/logout/RiLogoutBoxLine.vue'
import ClarityAdministratorSolid from '../../../components/icons/authentication/admin/ClarityAdministratorSolid.vue'
import IcSharpPersonAddAlt from '../../../components/icons/authentication/signup/IcSharpPersonAddAlt.vue'
import MaterialSymbolsPerson from '../../../components/icons/authentication/user/MaterialSymbolsPerson.vue'
import MaterialSymbolsVerifiedUserOutline from '../../../components/icons/authentication/moderator/MaterialSymbolsVerifiedUserOutline.vue'
import MaterialSymbolsAccountCircle from '../../../components/icons/authentication/profile/MaterialSymbolsAccountCircle.vue'
import Tooltip from "../../../components/edit_mode/tooltip/Tooltip.vue";

export default {
  components: {
    IcSharpHome,
    RiLoginBoxLine,
    RiLogoutBoxLine,
    ClarityAdministratorSolid,
    IcSharpPersonAddAlt,
    MaterialSymbolsPerson,
    MaterialSymbolsVerifiedUserOutline,
    MaterialSymbolsAccountCircle,
    Tooltip
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_MODERATOR');
      }

      return false;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  }
};
</script>
