import sourceData from '@/data/home_preview_projects.json'

/*
    Pour effectuer la vérification de l'existence de la route dans notre router
    il nous faut récupérer les données du fichier .json
*/

const EDIT_MODE_ROUTES = [
    {
        name: 'MainPageEditMode',
        path: '/main-page-edit-mode',  // WARNING ==> you need to delete "edit-mode" from "main-page-edit-mode"
        component: () => import(/*webpackChunkName:"MainPageEditMode"*/"@/components/edit_mode/pages/MainPageEditMode.vue"),
        meta: {
             title:'LNACARLA - MAIN EDIT MODE',
             transition: '',
             mode:'EDITMODE'
        },
        beforeEnter(){
            document.body.style.overflowY = "visible"
        }
    },
    {
        name: 'HomeEditMode',
        path: '/home-edit-mode',  // WARNING ==> you need to delete "edit-mode" from "home-edit-mode"
        component: () => import(/*webpackChunkName:"HomeEditMode"*/"@/components/edit_mode/pages/HomeEditMode.vue"),
        meta: {
             title:'LNACARLA - HOME EDIT MODE',
             transition: '',
             mode:'EDITMODE'
        },
        beforeEnter(){
            document.body.style.overflowY = "visible"
        }
    },
    {
        name: 'AboutEditMode',
        path: '/about-edit-mode', // WARNING ==> you need to delete "edit-mode" from "about-edit-mode"
        component: () => import(/*webpackChunkName:"AboutEditMode"*/"@/components/edit_mode/pages/AboutEditMode.vue"),
        meta: {
             title:'LNACARLA - ABOUT EDIT MODE',
             transition: '',
             mode:'EDITMODE'

        },
        beforeEnter(){
            document.body.style.overflowY = "visible"
        }
    },
    {
        name: 'ComingSoonOrMaintenanceEditMode',
        path: '/coming-soon-edit-mode',  // WARNING ==> you need to delete "edit-mode" from "coming-soon-edit-mode"
        component: () => import(/*webpackChunkName:"ComingSoonOrMaintenanceEditMode"*/"@/components/edit_mode/pages/ComingSoonOrMaintenanceEditMode.vue"),
        meta: {
             title:'LNACARLA - COMING SOON EDIT MODE',
             transition: '',
             mode:'EDITMODE'
        },
        beforeEnter(){
            document.body.style.overflowY = "visible"
        }
    },
    {
        name: 'NotFoundEditMode',
        path: '/not-found-edit-mode',  // WARNING ==> you need to delete "edit-mode" from "not-found-edit-mode"
        component: () => import(/*webpackChunkName:"NotFoundEditMode"*/"@/components/edit_mode/pages/NotFoundEditMode.vue"),
        meta: {
             title:'LNACARLA - NOT FOUND EDIT MODE',
             transition: '',
             mode:'EDITMODE'
        },
        beforeEnter(){
            document.body.style.overflowY = "visible"
        }
    },
    {
        name: 'ProjectsEditMode',
        path: '/projects-edit-mode',  // WARNING ==> you need to delete "edit-mode" from "projects-edit-mode"
        component: () => import(/*webpackChunkName:"ProjectsEditMode"*/"@/components/edit_mode/pages/ProjectsEditMode.vue"),
        meta: {
             title:'LNACARLA - PROJECTS EDIT MODE',
             transition: '',
             mode:'EDITMODE'
        },
        beforeEnter(){
            document.body.style.overflowY = "visible"
        }
    },
    {
        name: 'ProjectShowEditMode',
        path: '/portfolio-edit-mode/:slug',  // WARNING ==> you need to delete "edit-mode" from "projects-show-edit-mode"
        component: () => import(/*webpackChunkName:"ProjectShowEditMode"*/"@/components/edit_mode/pages/ProjectShowEditMode.vue"),
        props: route => ({...route.params, slug: route.params.slug}),
        meta:(route) => ({ title: route.params.slug, transition: 'right', mode:'EDITMODE' }),

        beforeEnter(to){
            // document.body.scrollTop = 0;

            /*
                On renvoie la valeur de project si le slug de la route du projet
                est strictement égale à celui du projet récupérée depuis le fichier.json
            */

            const exists = sourceData.projects.find(
                // L'id doit être une nombre
                // project => project.id === parseInt(to.params.id)
                project => project.slug === to.params.slug
            )

            // console.log("to.params           =", to.params)
            // console.log("sourceData.projects =", sourceData.projects)
            // console.log("to.params.slug      =", to.params.slug)

            // Si l’id n’existe pas ou undefined : on retourne le composant NotFound.vue
            if(!exists){
                // console.log("path       =", to.path)
                // console.log("pathMatch =", {pathMatch: to.path.split('/').slice(1)})

                return{
                    name:'NotFound',
                    // Allows keeping the url while rendering  different page
                    params: {pathMatch: to.path.split('/').slice(1)},
                    path: to.params.slug,
                    query: to.query,
                    hash: to.hash
                }
            }
        },
    },
    {
        name: 'ProjectsListEditMode',
        path: '/projects-list-edit-mode',  // WARNING ==> you need to delete "edit-mode" from "projects-list-edit-mode"
        component: () => import(/*webpackChunkName:"ProjectsListEditMode"*/"@/components/edit_mode/pages/ProjectsListEditMode.vue"),
        meta:{
             title:'LNACARLA - PROJECT LIST EDIT MODE',
             transition: '',
             mode:'EDITMODE'
        },
        beforeEnter(){
            document.body.style.overflowY = "visible"
        }
    },

]


export {
    // ---------------- EDIT MODE PAGE ROUTES --------------
    EDIT_MODE_ROUTES
  };