
<style scoped>
    @import './assets/css/app.scss';
</style>
<template>

        <div v-if="$route.name !== 'error' && $route.name !== 'Projects'"
             class="app__header">
            <div
                class="app__progress-container">
                <div class="app__progress-bar" id="app__myBar">
                </div>
            </div>
        </div>
        <!--
            Preloader and HomeIntroduction Animation
            Display Intro Animation Preloader once
        -->

        <!-- <div :v-if="count === 0">
            <Preloader/>
        </div> -->

        <!-- Copyright Message -->
        <div class="app__alert">
            <strong>Copyrighted :</strong>
            The images on this website are copyrighted and not available for download.
        </div>

        <!-- On affiche la barre de navigation partout sauf sur la page NotFound.vue -->
        <div v-if="$route.name !== 'NotFound' && $route.meta.mode !== 'EDITMODE'">
            <TheNavigation :routeName="$route.name"/>
        </div>

        <div v-if="$route.meta.mode === 'EDITMODE'">
            <TheNavigationEditMode/>
        </div>

        <!-- The about have a specific dynamic color -->
        <!-- <div v-if="$route.name !== 'About'"
            class="App__container">
            <router-view v-slot="{Component}" >
                <transition name="slide">
                    <component :is="Component" :key="$route.path"></component>
                </transition>
            </router-view>
        </div> -->
        <!-- The about have a specific static color -->
        <!-- <div v-if="$route.name === 'About'"
            class="App__container">
            <router-view v-slot="{Component}" >
                <transition name="slide">
                    <component :is="Component" :key="$route.path"></component>
                </transition>
            </router-view>
        </div> -->
        <!-- Will apply the same transition for all routes
            https://router.vuejs.org/guide/advanced/transitions.html -->
        <!-- <router-view v-slot="{ Component }">
            <transition name="fade">
                <component :is="Component" />
            </transition>
        </router-view> -->
    <router-view v-slot="{ Component, route}">
        <!-- use a dynamic transition name -->
        <transition :name="route.meta.transitionName">
            <component :is="Component" :key="$route.params.id"/>
        </transition>
    </router-view>
</template>

<script scoped>
    import TheNavigation from "@/components/TheNavigation.vue"
    import TheNavigationEditMode from "@/components/edit_mode/navigation/TheNavigationEditMode.vue"
    import gsap from 'gsap'
    import $ from 'jquery'
    // import Preloader from '@/components/Preloader.vue'
    import { //defineComponent,
             getCurrentInstance } from "vue";

    export default {
        name: 'App',
        data: () => ({
            navigationProps:
            getCurrentInstance().appContext.config.globalProperties.$navigationProps,
            count:0,
            currentPath: ''
        }),
        components: {
                        TheNavigation,
                        // Preloader,
                        TheNavigationEditMode
                    },
        computed:{
            routeInfo(){
                // console.log("this.$route =", this.$route)
                return this.$route
            },
        },
        methods: {
            toggleIntroAnimationPreloader() {
                this.count=+1
                // console.log("this.count =", this.count)
            },
        },
        mounted: function(){
            // var currentRouteProject = this.$router.currentRoute;
            // console.log('currentRouteProject._rawValue.path =', currentRouteProject._rawValue.path);

            /*
                [1]. Disable Right Click
                [2]. Disable Ctrl+c
                [3]. Disable Ctrl+v
                [4]. Disable Ctrl+x
                [5]. Disable Ctrl+s
                [6]. Disable Ctrl+p
                [7]. Disable Ctrl+w
                [8]. Disable Ctrl+u
                [9]. Disable Ctrl+a
                [10]. Disable Ctrl+z
            */
            this.toggleIntroAnimationPreloader()

            document.body.addEventListener('keydown', event => {
            if (event.ctrlKey && 'cvxspwuaz'.indexOf(event.key) !== -1) {
                event.preventDefault();
                // console.log("The images on this page are copyrighted and not available for download.")
               }
            })

            /*
                Prevent Image Dragging using JavaScript “ondragstart” event
            */
            document.body.ondragstart = function () {
                // console.log("The images on this page are copyrighted and not available for download.")
                return false;
            };

            /*
                Disable Right click
            */
            $(document).ready(function() {
                $('body').on({
                    "contextmenu": function(e) {
                        // console.log("ctx menu button:", e.which);
                        // Display block alert message
                        var tl = gsap.timeline();
                        tl.set(".app__alert", {display: "block"});
                        tl.pause(5)
                        tl.play()
                        // tl.to(".app__alert", {opacity: 0});
                        tl.to(".app__alert", {display: "none"});
                        // tl.to(".app__alert", 1  , {opacity: 1});

                        // alert('The images on this page are copyrighted and not available for download');

                        // Stop the context menu
                        e.preventDefault();
                    }
                });
            });

            // Horizontal bar animation showing scroll vertical scroll progress
            // if ($('app__myBar').length > 0) {
                window.onscroll = function() {myFunction()};
            //}

            function myFunction() {
                var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
                var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
                var scrolled = (winScroll / height) * 100;

                // Determining if an element exists as part of the visible DOM
                if (document.body.contains(document.getElementById("app__myBar"))) {
                    document.getElementById("app__myBar").style.width = scrolled + "%";
                }
            }
        }
    }
</script>