// WHERE ARE WE ? ==> FRONTEND
import axios from 'axios';
import authHeader from './auth-header';

const API_URL =  process.env.NODE_ENV === 'production'
        // on production mode => remove server => Doesn't work on server
        ? "https://back-end.lnacarla.com/api/test/"
        // on development mode => works for local server
        : "http://localhost:8080/api/test/"

// console.log("process.env.NODE_ENV                    =",  process.env.NODE_ENV )
// console.log("API_URL =",  API_URL)

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
}

export default new UserService();
