import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from "./store";
import directives from "./directives/";

let app = createApp(App)

// register all directives
directives(app);

app.config.devtools = true

// add global variable router and vueX store
// app.use(router).use(store)

app.use(router)

app.mount('#app')

