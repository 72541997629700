
const EDIT_MODE_PROJECTS_ROUTES  =  [
  {
    path: "/edit-mode-projects",
    alias: "/edit-mode-projects",
    name: "projects",
    component: () => import(/*webpackChunkName:"ProjectsList"*/"@/components/edit_mode/projects/ProjectsList.vue"),
    meta: {
      title:'LNACARLA - EDIT MODE',
      transition: '',
      mode:'EDITMODE'
    },
  },
  {
    path: "/edit-mode-projects/:id",
    name: "project-details",
    component: () => import(/*webpackChunkName:"Project"*/"@/components/edit_mode/projects/Project.vue"),
    meta: {
      title:'LNACARLA - EDIT MODE',
      transition: '',
      mode:'EDITMODE'
    },
  },
  {
    path: "/edit-mode-projects/add",
    name: "add",
    component: () => import(/*webpackChunkName:"AddProject"*/"@/components/edit_mode/projects/AddProject.vue"),
    meta: {
      title:'LNACARLA - EDIT MODE',
      transition: '',
      mode:'EDITMODE'
    },
  }
];


export {
   EDIT_MODE_PROJECTS_ROUTES
};