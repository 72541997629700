
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
    <path fill="currentColor" d="M10 20v-6h4v6h5v-8h3L12 3L2 12h3v8h5z"></path>
  </svg>
</template>

<script>
// https://icones.js.org/collection/all?s=home
// https://api.iconify.design/ic:sharp-home.svg
export default {
  name: 'IcSharpHome'
}
</script>