
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
    <path fill="currentColor"
      d="M4 18h2v2h12V4H6v2H4V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3zm2-7h7v2H6v3l-5-4l5-4v3z">
    </path>
  </svg>
</template>

<script>
// https://icones.js.org/collection/all?s=logout
// https://api.iconify.design/ri:logout-box-line.svg
export default {
  name: 'RiLogoutBoxLine'
}
</script>