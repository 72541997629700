
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
    <path fill="currentColor"
      d="M4 15h2v5h12V4H6v5H4V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-6zm6-4V8l5 4l-5 4v-3H2v-2h8z">
    </path>
  </svg>
</template>

<script>
// https://icones.js.org/collection/all?s=login
// https://api.iconify.design/ri:login-box-line.svg
export default {
  name: 'RiLoginBoxLine'
}
</script>